/* SITE ADVERT SKIN */

/* =================== */

$sdc-site-mpu-background: var(--component-shade) !default;
$sdc-site-mpu-placeholder: var(--component-shade) !default;
$sdc-site-mpu-padding: 16px !default;
$sdc-site-mpu-label-padding: 12px !default;
$sdc-site-mpu-edge-margin-offset: -16px !default;
$sdc-site-mpu-edge-to-edge-breakpoint: 480px !default;

$sdc-site-mpu-label-font-size: .8rem !default;
$sdc-site-mpu-label-color: var(--text-color-secondary) !default;
$sdc-site-mpu-label-margin: .75em !default;

$sdc-site-mpu-full-bleed-background:var(--component-shade) !default;
$sdc-site-mpu-full-bleed-padding: calc(0.5em + 0.7vmin);

/* Media Query Variables */
$sdc-site-mpu-media-display: "(min-width: 1000px)" !default;
$sdc-site-mpu-media-tablet: "(min-width: 800px) and (max-width: 999px)" !default;
$sdc-site-mpu-media-tablet-overlap: "(min-width: 740px) and (max-width: 799px)" !default;
$sdc-site-mpu-media-mobile: "(max-width: 739px)" !default;
$sdc-site-mpu-media-edge-to-edge: "(max-width: 480px)" !default;

.sdc-site-au--amp {

  .sdc-site-au__label {
    display: block;
    text-align: center;
    font-size: $sdc-site-mpu-label-font-size;
    line-height: 1;
    margin-bottom: $sdc-site-mpu-label-margin;
    color: $sdc-site-mpu-label-color;
  }

  &.mpu-inline-mobile {
    background: $sdc-site-mpu-background;
    background: radial-gradient(ellipse at center, #fff 0%, $sdc-site-mpu-background 100%);
    padding: $sdc-site-mpu-padding 0;
  }

  .sdc-site-au__item {
    background: $sdc-site-mpu-placeholder;
    display: block;
    margin: 0 auto;
  }
}

// Display edge to edge for small screens to avoid layout issues when text column gets narrower than mpu
@media all and (max-width: $sdc-site-mpu-edge-to-edge-breakpoint) {

  .sdc-site-au--amp {

    &.mpu-inline-mobile {
      margin-left: $sdc-site-mpu-edge-margin-offset;
      margin-right: $sdc-site-mpu-edge-margin-offset;
    }
  }
}
