@media screen and (min-width: 1000px) {
    .smartfeed-ad-mobile {
        display: none;
    }
  }
  
@media screen and (min-width: 0px) and (max-width: 999px) {
    .smartfeed-ad-desktop {
        display: none;
    }
}