$racing-primary: #014350;
$racing-secondary: #006270;
$racing-secondary-current: #005763;
$sdc-font-path-display: '/node_modules/ui-project-base/fonts/sky-display-medium.woff';

.sdc-site-localnav--horseracing[class],
.sdc-site-localnav--horseracing[class] *[class] {
  background-color: $racing-secondary;
  color: white;
  border-color: rgba(225,255,225,0.2)!important;
  fill: white;
}
// main nav

.blackjack-sdc-digrev-header + .sdc-site-localnav--horseracing[class] a[class]:not(.sdc-site-localnav__header-title):hover,
.blackjack-sdc-digrev-header + .sdc-site-localnav--horseracing[class] a[class][aria-current] {
  background-image: linear-gradient(to left, white 0, white 100%);
}

.sdc-site-localnav--horseracing[class] .sdc-site-localnav__header-title > svg[class] {
  fill: white!important;
  // left: 0;
}

.sdc-site-localnav--horseracing[class] .sdc-site-localnav__header > a {
  font-family: "Sky Display";
  font-weight: normal;
}
// second nav

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] {
  box-shadow: none;
  background-color: $racing-primary;
  margin-bottom: 0;
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] [class] {
  background-color: $racing-primary;
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] a[class] {
  font-weight: bold;
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] a[class]:hover {
  background: #005763!important;
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] .sdc-site-localnav__item-link[aria-current] {
  background: $racing-secondary-current!important;
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] .sdc-site-localnav__item-link {
  padding-left: 1em;
  padding-right: 1em;
}

.sdc-site-localnav--horseracing[class] 
.sdc-site-localnav__item-link[data-flagged="true"]:before {
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGZpbGw9IiNFQTAyMUEiIGN4PSI5IiBjeT0iOSIgcj0iOSIvPjxjaXJjbGUgZmlsbD0iI0ZGRiIgY3g9IjkiIGN5PSI5IiByPSI2Ii8+PC9nPjwvc3ZnPg==) no-repeat;
  background-size: 100%;
  display: inline-block;
  width: 0.9em;
  height: 1.1em!important;
  vertical-align: middle;
  position: relative;
  top: 0;
  left: -3px;
  margin-right: 0.3em;
  content: "";
}

.sdc-site-localnav ~ .sdc-site-localnav--horseracing[class] .sdc-site-localnav__item:first-child {
  margin-left: 0.7em;
}

.sdc-site-localnav--horseracing[class] .sdc-site-localnav__header[class]:after {
  background: white;
}

.sdc-ui-external-link:after {
  width: 0;
  height: .9em;
  margin-left: 0;
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  background: 0;
  background-size: 100%;
  opacity: .5;
}

@media (min-width: 540px) {
  .sdc-site-localnav--horseracing[class] .sdc-site-localnav__header-title {
    display: table-cell;
    height: 2.85em;
    padding-right: 1.3em;
    padding-left: .7em;
    vertical-align: middle;
    pointer-events: auto !important;
  }
}

@media (hover: none){
  .sdc-site-localnav--horseracing a[href]:hover {
    background: $racing-secondary!important;
  }
}