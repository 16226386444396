.sdc-site-marketing-banner {
    align-items: center;
    display: flex;
    font-size: 2.3em!important;
    justify-content: center;
    margin-bottom: 0.75em!important;
}

.sdc-site-marketing-banner a {
    padding: 0 20px;
}

.sdc-site-marketing-banner__image--show-desktop {
    width: 100%;
    max-width: 976px;
}

.sdc-site-marketing-banner__image--show-mobile {
    display: none;
}

@media (min-width: 769px) {
    .sdc-site-load-more ~ .sdc-site-marketing-banner {
        top: -45px;
        position: relative;
    }
}

@media (max-width: 768px) {
    .sdc-site-marketing-banner__image--show-mobile {
        display: block;
    }
    .sdc-site-marketing-banner__image--show-desktop {
        display: none;
    }
    .sdc-site-load-more ~ .sdc-site-marketing-banner {
        top: -45px;
        position: relative;
    }
}

@media (max-width: 600px) {
    .sdc-site-load-more ~ .sdc-site-marketing-banner {
        top: -30px;
        position: relative;
    }
}

body > div.ui-sitewide-component-header__wrapper--h1 {
    background-color: transparent !important;
}