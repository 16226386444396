.sdc-site-select-nav__group {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
  @extend .site-component-vertical-margin-s!optional;
}

.sdc-site-select-nav__group > div {
  @extend .site-component-vertical-margin-s!optional;
}

@media all and (max-width: 480px){
  .sdc-site-select-nav__group {
    padding-bottom: 6px;
  }
}

.sdc-site-select-nav__body {
  z-index: 100000
}
