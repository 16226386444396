/* To move to project-base when DE needs these styles */
.main {
    /* Using padding instead of margin to avoid gaps between content landmarks */
    margin: 0;
    padding-top: var(--component-margin);
    padding-bottom: var(--group-margin);
}

/* Counteract top-padding when elements need to be flush to the top edge of <main> */
.main > .sdc-site-layout-sticky-region:first-child,
.main > .sdc-site-layout-sticky-region:first-child + .ui-advert,
.main > .ui-advert:first-child,
.main > .section-wrap:first-child {
    margin-top: calc(-1 * var(--component-margin));
}