@import "../../../node_modules/ui-sitewide-localnav/styles/components/skins/all";

.sdc-site-localnav[class],
.sdc-site-localnav[class] ~ .sdc-site-localnav[class] {
  z-index: 10000;
}

.sdc-site-localnav.sdc-site-localnav--horseracing ~ .sdc-site-localnav[class]  {
  z-index: 9999
}

.sdc-site-layout-sticky-region[class] ~ .sdc-site-localnav[class] {
  z-index: 9990;
}

.main .sdc-site-localnav {
  z-index: unset;
}

.sdc-site-localnav__more[class] {
  z-index: 9999;
  -webkit-transform: translate3d(0,0,0);
}