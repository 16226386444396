@import "../../../node_modules/sdc-site-a11y-skip-links/styles/components/sdc-site-a11y-skip-links.scss";
@import "../../../node_modules/ui-digrev-header/styles/components/ui-digrev-header.scss";
@import "../../../node_modules/ui-digrev-footer/styles/components/ui-digrev-footer.scss";
@import "../../../node_modules/ui-sitewide-component-header/styles/components/ui-sitewide-component-header.scss";
@import "../../../node_modules/sdc-site-roadblock/styles/components/sdc-site-roadblock.scss";
@import "../../../node_modules/sdc-site-sponsor/styles/components/sdc-site-sponsor.scss";
@import "../../../node_modules/ui-tool-auth-manager/styles/components/sdc-site-auth-manager.scss";
@import "../../../node_modules/sdc-site-view-manager/styles/components/sdc-site-view-manager.scss";
@import "../../../node_modules/ui-sitewide-video/styles/components/ui-sitewide-video.scss";
@import "../../../node_modules/sdc-site-a11y-skip-links/styles/components/sdc-site-a11y-skip-links.scss";
@import "../../../node_modules/ui-tool-consent-management/styles/components/ui-tool-consent-management.scss";
@import "../../../node_modules/ui-digrev-header/styles/components/ui-digrev-header.scss";
@import "../../../node_modules/ui-sitewide-header/styles/components/ui-sitewide-header.scss";
@import "../../../node_modules/ui-sitewide-component-header/styles/components/ui-sitewide-component-header.scss";
@import "../../../node_modules/sdc-site-roadblock/styles/components/sdc-site-roadblock.scss";
@import "../../../node_modules/sdc-site-sponsor/styles/components/sdc-site-sponsor.scss";
@import "../../../node_modules/ui-tool-auth-manager/styles/components/sdc-site-auth-manager.scss";
@import "../../../node_modules/sdc-site-view-manager/styles/components/sdc-site-view-manager.scss";
@import "../../../node_modules/sdc-site-cookie-notice/styles/components/sdc-site-cookie-notice.scss";
@import "../../../node_modules/ui-sitewide-layout/styles/components/ui-sitewide-layout";
@import "../uk-component-skins/amp/sdc-site-mpu.scss";
@import "../../../node_modules/sdc-site-sponsor/styles/components/sdc-site-sponsor.scss";
@import "../../../node_modules/sdc-site-roadblock/styles/components/sdc-site-roadblock.scss";
@import "../../../node_modules/sdc-site-view-manager/styles/components/sdc-site-view-manager.scss";
@import "../../../node_modules/@gdp/ui-advert-manager/styles/components/ui-advert-manager";
@import "../../../node_modules/ui-digrev-header/styles/components/ui-digrev-header.scss";
@import "../../../node_modules/ui-gpt-lazyload-debugger/styles/components/ui-gpt-lazyload-debugger.scss";
@import "../../../node_modules/ui-tennis-match-score/styles/components/ui-tennis-match-score.scss";
@import "../../../node_modules/ui-tool-auth-manager/styles/components/sdc-site-auth-manager.scss";
@import "../../../node_modules/@gdp/ui-tournament-matches/styles/components/ui-tournament-matches.scss";
@import "../../../node_modules/ui-site-footer/styles/components/ui-site-footer";
@import "../../../node_modules/ui-sitewide-component-header/styles/components/ui-sitewide-component-header.scss";
@import "../../../node_modules/sdc-site-footer/styles/components/sdc-site-footer";
@import "../../../node_modules/ui-sitewide-video/styles/components/ui-sitewide-video.scss";

// Common component styling

.live-updating-text {
  text-align: right;
  margin-top: calc(-1 * var(--component-gap));
  font-size: var(--display-50);
  font-style: italic;
  color: var(--text-color);
}