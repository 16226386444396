@import "../../../node_modules/sdc-site-hero/styles/components/sports/all";

.sdc-site-layout-sticky-region + .sdc-site-hero,
.sdc-site-au--full-bleed + .sdc-site-hero {
  @extend .site-component-vertical-margin-negative!optional;
}

@media all and (max-width: 600px) {
  .sdc-site-hero .sdc-site-hero__snippet {
    display: block;
    font-size: 1rem;
  }
}