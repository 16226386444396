 // result color
 $sdc-site-match-header-live-background: white;
 $sdc-site-match-header-live-color: #222;
 $sdc-site-match-header-result-border-color: transparent;
 
 // live color
 $sdc-site-match-header-result-background: #d0021b;
 $sdc-site-match-header-result-color: white;
 $sdc-site-match-header-in-progress-background: white;
 
 .sdc-site-match-header__betting-list + .sdc-site-match-header__betting-link:after {
  content: "";
  opacity: 0.9;
  margin-left: 0.25em;
  transform: rotate(-45deg);
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.sdc-site-match-header__betting-list + .sdc-site-match-header__betting-link {
  text-wrap: auto;
  white-space: unset;
}
