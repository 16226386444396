.sdc-article-gallery__control--prev,
.sdc-article-gallery__control--next {
  &:after {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    content: '';
    width: 14px;
    height: 28px;
    background-size: 99%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}

.sdc-article-gallery__control--prev:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMjgnIHZpZXdCb3g9JzAgMCAxNCAyOCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48dGl0bGU+UHJldmlvdXM8L3RpdGxlPjxwYXRoIGQ9J00xMi42MzcgMGMtLjExNyAwLS4yMy4wMjctLjMzOC4wODItLjExLjA1NS0uMTkuMTEtLjI0LjE2NEwuMzUgMTMuMDE2Yy0uMjMzLjIzNy0uMzUuNTI4LS4zNS44NzVzLjExNy42NC4zNS44NzZsMTEuNTg1IDEyLjYzMmMuMTE3LjEyOC4yMTMuMjA2LjI5LjIzMy4wNzQuMDI4LjE3LjAzNy4yODcuMDI4LjExNy0uMDEuMjM0LS4wNi4zNS0uMTVsLjQ1Mi0uMzU2Yy4yNS0uMjM3LjQzNS0uNDgzLjU1Mi0uNzM4LjAxNy0uMDU1LjA0Mi0uMDkuMDc1LS4xMWguMDVMMi41MDggMTMuNzgyIDEzLjk5IDEuMjZsLS4xLS4xMzdjLS4xMTYtLjEyNy0uMjE2LS4yNTQtLjMtLjM4Mi0uMDgzLS4xMjctLjE3NS0uMjQ2LS4yNzYtLjM1NS0uMTE3LS4wNzMtLjIzNC0uMTU1LS4zNS0uMjQ2LS4xMTgtLjA5LS4yMjYtLjEzNy0uMzI3LS4xMzd6JyBmaWxsPScjRkZGJyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=);
}

.sdc-article-gallery__control--next:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMjgnIHZpZXdCb3g9JzAgMCAxNCAyOCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48dGl0bGU+TmV4dDwvdGl0bGU+PHBhdGggZD0nTS42NzcuMzgzQy41NzcuNDkzLjQ4NS42MS40LjczOC4zMi44NjYuMjI3Ljk5My4xMjYgMS4xMkwwIDEuMjYgMTEuNDg0IDEzLjc4IDAgMjYuMzA2aC4wNWMuMDM0LjAxOC4wNi4wNTQuMDc1LjExLjExNy4yNTQuMy41LjU1Mi43MzdsLjQ1LjM1NmMuMTE4LjA5LjIzNS4xNC4zNTIuMTUuMTE2LjAxLjIxMyAwIC4yODgtLjAyNy4wNzUtLjAyNi4xNy0uMDk1LjI4OC0uMjA0bDExLjU4NC0xMi42NmMuMjM0LS4yMzcuMzUtLjUzLjM1LS44NzUgMC0uMzQ2LS4xMTYtLjYzNy0uMzUtLjg3NEwxLjkzLjI0NkMxLjg4LjE5IDEuOC4xMzYgMS42OTQuMDgyIDEuNTgzLjAyNyAxLjQ3NSAwIDEuMzY3IDBjLS4xMSAwLS4yMjIuMDQ2LS4zNC4xMzdDLjkxMi4yMjcuNzk1LjMxLjY3OC4zODN6JyBmaWxsPScjRkZGJyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=);
}