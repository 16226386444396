.section-wrap .ui-sitewide-component-header__details {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


@media (min-width: 601px) and (max-width: 680px) {

  .section-wrap .ui-sitewide-component-header__details {
    max-width: 540px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {

  .section-wrap .ui-sitewide-component-header__details {
    flex-direction: column;
    max-width: 540px;
    padding-left: 6.5vw;
    padding-right: 6.5vw;
  }

  .section-wrap .ui-sitewide-component-header__details .ui-sitewide-component-header--h2 {
    line-height: 26px;
  }
}

@media all and (max-width: 480px) {

  .section-wrap .ui-sitewide-component-header__details {
    flex-direction: column;
    max-width: 390px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
