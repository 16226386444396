.sdc-site-outbrain--AR_3,
.sdc-site-outbrain--AR_4 {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
  padding-bottom: var(--component-padding);
}

.sdc-site-outbrain--AR_5 {
  margin-bottom: var(--group-margin);
}

