$sdc-font-path-regular: '/node_modules/ui-project-base/fonts/sky-regular.woff';
$sdc-font-path-medium: '/node_modules/ui-project-base/fonts/sky-medium.woff';

// $sdc-site-racing-header-bg: linear-gradient(to bottom, #005763, #014a57)!default;
// $sdc-site-racing-header-body-bg: linear-gradient(284deg, #006270, #046775)!default;
// $sdc-site-racing-header-color: #fff!default;
$sdc-site-racing-status-color: #005763!default;

.sdc-site-racing-header[class] {
  background: linear-gradient(to bottom, #005763, #014a57);
  color: white;
}

.sdc-site-racing-header__body[class] {
  background-image: linear-gradient(284deg, #006270, #046775);
  background-color: transparent;
  border: 5px solid rgba(0,0,0,0);
  background-clip: padding-box;
}

.sdc-site-racing-header__body:before {
  border: 1px solid rgba(255,255,255,0.1);
}

.sdc-site-racing-header__inner {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
}

.sdc-site-racing-header__body {
  @extend .glints-box!optional;
}