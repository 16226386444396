$sdc-site-simple-text-highlight-color: #ea021a;

// add everything below to the skin file for this component
.sdc-site-simple-text:not(.no-skin)
.sdc-site-simple-text__inner:not(.no-skin) {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
  @extend .site-wrap--mobile-edge!optional;
}

.sdc-site-simple-text:not(.no-skin)
.sdc-site-simple-text__body[class] {
  @extend %glints-box!optional;
  @extend %glints-box--mobile-edge!optional;
}
