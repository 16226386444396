.sdc-article-header__wrap[class] {
  max-width: 976px !important;
}

.sdc-article-header__title[class],
.sdc-article-header__sub-title[class] {
  max-width: 800px;
}

.sdc-article-image__caption-text[class][class] {
  max-width: 100%!important;
}

.sdc-site-layout-sticky-region__ghost + .sdc-article-header--feature {
  @extend .site-component-vertical-margin-negative!optional;
}

@media all and (min-width: 940px){
  .sdc-site-layout
  .sdc-article-quote--with-image[class] {
    margin-left: 24px;
    margin-right: 24px;
  }
}