// Sets inline with content when smartfeed is unavailable
div[data-spotim-module="recirculation"],
div[data-spot-im-module-default-area="conversation"] {
  max-width: var(--site-width);
  padding-left: var(--site-gutter);
  padding-right: var(--site-gutter);
  margin: 0 auto;
}

// Resets to defaults if smartfeed is displaying
.ob-smartfeed-joker div[data-spotim-module="recirculation"] {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}