// add everything below here to the skin file for this component
.sdc-site-racing-fastresults__inner {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
  @extend .site-wrap--mobile-edge!optional;
}

.sdc-site-racing-fastresults__item {
  @extend %glints-box!optional;
  @extend %glints-box--mobile-edge!optional;
}

@media all and (max-width: 480px){
  .sdc-site-racing-fastresults__body[class] {
    grid-gap: 8px;
  }
}
