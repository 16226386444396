.mobile-article-embed {
    padding-bottom: 2rem;
}

.mobile-article-embed .sdc-article-header__wrap[class],
.mobile-article-embed .section-wrap__article-column[class],
.mobile-article-embed .sdc-article-liveblog,
.mobile-article-embed .ui-liveblog,
.mobile-article-embed .sdc-article-body {
    max-width: 100% !important;
    padding: 0px;
    margin: 0px;
}

.mobile-article-embed .sdc-article-header[class] {
    padding-bottom: 0;
}