.blackjack-sdc-site-live-on-sky__category[class] {
  @extend %blackjack-sdc-ui-label!optional;
}

.site-nav-desktop__watch-button {
  @include sdc-ui-button(white,  $pictor);
}

.sdc-site-promo-panel__inner {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
}

.sdc-site-login-panel__inner {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
}


.sdc-site-nba-carousel {
   @extend .site-wrap!optional;
   @extend .site-wrap-padding!optional;
   @extend .site-wrap--mobile-edge!optional;
}

.sdc-site-nba-carousel {
  max-width: auto;
  margin: 0 auto;
}

// fix spacing on related articles when no
// sdc-article-sibling-links are present
.related-articles-wrap.section-wrap--shade {
  padding-bottom: 20px;
}

// inconsistency on 1 col and 2 cols broke video style
// temp fix for now
// @TODO will need proper fix later when 2 cols take over
.sdc-site-video:not(.sdc-article-widget--full-bleed) {
  max-width: 976px !important;
  margin-left: auto;
  margin-right: auto;
}

// @TODO to be fixed properly in COSMO-1167
.sdc-article-gallery:not(.sdc-article-widget--full-bleed),
.sdc-site-video:not(.sdc-article-widget--full-bleed) {
  max-width: 976px !important;
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
}

// Experiment to view effects of reducing mpu scroll depth on nba articles only
.sdc-site-layout--alt2 .reduce-mpu-scroll-depth {
  max-height: 2500px !important;
}

// Override that fixes the width of the schedule olympics widget on /olympics/schedule
// Prevously would stretch into the sidebar ads
.sdc-site-layout__col1.hide-overflow {
  overflow: hidden;
}

// Fixes for advert styling issues with F1 GP race pages
.sdc-site-local-nav-wrap .ui-sitewide-component-header--h2 {
  margin-top: var(--component-margin);
}

.advert--site-takeover .sdc-site-local-nav-wrap .ui-sitewide-component-header--h2 {
  padding-top: var(--component-margin);
}

.sdc-site-local-nav-wrap .sdc-site-localnav {
  z-index: 1;
}

.vjs-modal-dialog-content .vjs-track-settings-font .vjs-track-setting select {
  background-color: #fff;
}

/* Reinstate the advert manager grid style for MPU when it appears as part of a site tiles alt6 group */
.sdc-site-tiles--alt6 .ui-advert[data-ad-format^="mpu"].sdc-site-tiles__item {
  display: grid !important;
}

.sdc-site-fixres__additional-items {
  text-align: right !important;
  padding-top: 1.5em;
}

.sdc-site-fixres__additional-link {
  display: flex;
  font-size: 18px;
  flex-direction: row;
  color: #3157a1;
  font-weight: bold;
  align-items: center;
  justify-content: flex-end;
  align-items: baseline;
}

.sdc-site-fixres__additional-link:after {
  border-bottom: 1px solid;
  border-right: 1px solid;
  content: "";
  display: inline-block;
  height: .35em;
  margin-left: .25em;
  opacity: .9;
  transform: rotate(-45deg) translateY(-1px);
  width: .35em;
  margin-left: 10px;
}

.sdc-article-header .ui-sitewide-component-header__wrapper--h1 ~ .sdc-article-date__wrap {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
}

.sdc-article-header .sdc-article-date__icon {
  display: flex;
  margin-right: 6px;
}

.sdc-article-header .sdc-article-date__date-time {
    color: var(--text-color-secondary);
    font-size: 16px;
    line-height: 1.2;
}

.sdc-article-header .sdc-article-date__icon svg {
  fill: currentColor;
  height: 13px;
  width: 13px;
}
