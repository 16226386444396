@media all and (max-width: 740px) {
  div.section-wrap div.sdc-site-layout-sticky-region--liveblog[data-format="leaderboard"],
  body div ~ div.sdc-site-layout-sticky-region--matchpage-liveblog[data-format="leaderboard"][data-role="sticky-region"] {
    height: 2000px;
  }
}

@media all and (max-width: 960px) {
  .sdc-site-layout-sticky-region .sdc-site-layout-sticky-region__content > div:not(.ui-advert) {
    display: none;
  }

  .sdc-site-layout-sticky-region .sdc-site-layout-sticky-region__content {
    margin: var(--component-gap) 0 var(--component-gap) 0;
  }
}