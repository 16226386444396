@import "../../../node_modules/sdc-site-promo-panel/styles/components/mixins";

.sdc-interactive-super6 {
  padding-top: 2em;
  padding-bottom: 3em;
  background-color: #fff;
}

@media all and (min-width: 801px) {
  .sdc-interactive-super6 + .sdc-site-promo-panel {
    @include sdc-site-promo-panel-side-by-side;
  }
}