$sdc-site-mpu-full-bleed-padding: calc(0.5em + 0.5%) 0;
$sdc-site-mpu-full-bleed-background: #E0DEE4;

@media all and (max-width: 939px) {
  .hide-mpu--mobile {
    display: none !important;
  }
}

@media all and (min-width: 830px) {
  .show-mpu--mobile {
    display: none !important;
  }
}

.sdc-site-au__mpu--watch {
  margin-bottom: var(--group-margin);
}