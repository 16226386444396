.sdc-site-share__link {
  padding: 9px 19px !important;
}

$sdc-site-share-border-radius: 4px;
$sdc-site-share-border-color: #e2e2e2;
$sdc-site-share-button-bg-hover: #F4F5F7;

.site-share-wrapper {
  margin-bottom: 32px;
}
