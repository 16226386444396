.sdc-site-racing-meetings__text {
    font-size: calc(13px + 0.9vw);
    line-height: 1;
    padding: 2em 1em;
  }

.sdc-site-racing-meetings__body {
    padding: 2em 1em;
}
  
  @media all and (min-width: 1000px){
    .sdc-site-racing-meetings__text {
        font-size: 22px;
    }
  }