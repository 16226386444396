.sdc-site-racing-profile:not(.no-skin) .sdc-site-racing-profile__inner {
  @extend .site-wrap!optional;
  @extend .site-wrap-padding!optional;
  @extend .site-wrap--mobile-edge!optional;
}

.sdc-site-racing-profile:not(.no-skin) .sdc-site-racing-profile__body {
  @extend %glints-box!optional;
  @extend %glints-box--mobile-edge!optional;
}

.sdc-site-racing-header,
.sdc-site-racing-header ~ div {
  @extend .site-component-vertical-margin-s!optional;
}

.sdc-site-racing-profile a:hover {
  text-decoration: none;
}
