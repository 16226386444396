// Special case for the Around Sky tiles to fit inline with outbrain: creates a narrow width for the tile and alters the
// image sizing

.around-sky-wrap[class] {
  @extend .site-wrap !optional;
  @extend .site-component-vertical-margin !optional;
  padding-left: 25px;

  .sdc-site-tiles__item[class] {
    margin-left: 0;
    margin-right: 0;
  }
  
  .ui-sitewide-component-header__body {
    padding-left: 0;
    padding-right: 0;
  }
  
  .sdc-site-tile__figure[class] {
    margin: 0;
  }
}

@media (min-width: 860px) {

  .around-sky-wrap[class] {

    .sdc-site-tile {
      width: 100%;

      &__image-wrap,
      &__image {
        height: 100%;
      }

      &__image {
        width: auto;
        max-width: unset;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    .sdc-site-tile--has-link:hover .sdc-site-tile__image {
      transform: translateX(-50%) scale(1.05);
    }
  }
}

@media (max-width: 500px) {

  .around-sky-wrap[class] {
    padding-right: 25px;
  }

}